$(document).ready(function () {

    $(this).foundation();

    var vph, hh, nh, fh, ch;
    function dynHeight() {
        vph = $(window).height();
        hh 	= $('header.header').height();
        nh 	= $('#main-menu').height();
        fh 	= $('footer.footer').height();
        ch = Number(vph-hh-nh-fh-1);
        if($('.main-content').height() < ch) {
            //console.log(ch);
            $('.main-content').css('min-height', ch);
        }
    }
    //dynHeight();
    //$(window).on('resize', dynHeight);


    // document.querySelector('#show_button').addEventListener('click', function() {
    //     if (window.webkitNotifications.checkPermission() == 0) { // 0 is PERMISSION_ALLOWED
    //         // function defined in step 2
    //         window.webkitNotifications.createNotification(
    //             'icon.png', 'Notification Title', 'Notification content...');
    //     } else {
    //         window.webkitNotifications.requestPermission();
    //     }
    // }, false);


});